import React from "react";
import { StyleProp, StyleSheet, View, ViewStyle } from "react-native";

import { CreditLimitStatus } from "@ctv/core/providers/credit-limit/types";
import { appendTestId, Token } from "@ctv/shared-core/src";
import CreditIcon from "@traveloka/icon-kit-web/react/IcPaymentCreditCardFill";
import CreditUnavailableIcon from "@traveloka/icon-kit-web/react/IcPaymentCreditCardUnavailable16";
import XIcon from "@traveloka/icon-kit-web/react/IcSystemCrossClose";
import { creditLimitColorSwitcher } from "../../../_utils/credit-limit";

type IconSize = "normal" | "tiny";

type Props = {
  style?: StyleProp<ViewStyle>;
  status?: CreditLimitStatus;
  size?: IconSize;
  testID?: string;
  isSuspended?: boolean;
};

function CreditLimitIcon(props: Props) {
  const { style, status, size = "normal", testID, isSuspended } = props;

  const color = {
    backgroundColor: creditLimitColorSwitcher(status),
  };

  const componentSize = sizeSwitcher(size);
  const Icon = isSuspended ? CreditUnavailableIcon : CreditIcon;

  return (
    <View
      testID={appendTestId(testID, `credit-limit.icon.${status}`)}
      style={[componentSize.containerSize, style]}
    >
      <Icon
        color={isSuspended ? Token.color.blueDark : Token.color.darkStain}
        width={componentSize.iconSize}
        height={componentSize.iconSize}
      />
      {!isSuspended && (
        <View style={[Style.dot, color, componentSize.dotStyle]}>
          {status === undefined && (
            <XIcon
              color={Token.color.darkStain}
              width={componentSize.dotStyle.width - 2}
              height={componentSize.dotStyle.height - 2}
            />
          )}
        </View>
      )}
    </View>
  );
}

function sizeSwitcher(size: IconSize) {
  switch (size) {
    case "normal":
      return {
        containerSize: {
          width: 21,
          height: 21,
        },
        iconSize: 21,
        dotStyle: {
          width: 11,
          height: 11,
          left: 13,
          top: -3,
        },
      };
    case "tiny":
      return {
        containerSize: {
          width: 16,
          height: 16,
        },
        iconSize: 16,
        dotStyle: {
          width: 7,
          height: 7,
          left: 9,
          top: -2,
        },
      };
  }
}

const Style = StyleSheet.create({
  dot: {
    position: "absolute",
    borderRadius: Token.borderRadius.rounded,
    justifyContent: "center",
    alignItems: "center",
  },
});

export default CreditLimitIcon;
