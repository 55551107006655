"use client";
import React, { MouseEvent, ReactNode, useRef } from "react";
import { StyleSheet, View } from "react-native";

import Briefcase from "@traveloka/icon-kit-web/react/IcLinkToCareersFill16";
import Policy from "@traveloka/icon-kit-web/react/IcPolicyCheck16";
import Chevron from "@traveloka/icon-kit-web/react/IcSystemChevronDown16";
import UserAccount from "@traveloka/icon-kit-web/react/IcUserAccountFill";
import SignOut from "@traveloka/icon-kit-web/react/IcUserSignOutLogOut";

import FirstPicCoachmark from "@ctv/app/[locale]/(post-login)/_components/post-login/FirstPicCoachmark/FirstPicCoachmark";
import {
  PaymentMethod,
  useAuth,
  useContentResource,
  useProfile,
  useTracker,
} from "@ctv/core";
import { useAccountStatus } from "@ctv/core/providers/account-status/AccountStatusContext";
import {
  Button,
  Dropdown,
  Image,
  LocalizedLink,
  Text,
  Token,
} from "@ctv/shared-core/src";

import LanguageMenu from "../LanguageMenu/LanguageMenu";
import MenuItem from "../MenuItem/MenuItem";

import { useHeaderHeight } from "./HeaderHeightContext";
import ApprovalMenu from "./HeaderMenu/ApprovalMenu";
import CompanyMenu from "./HeaderMenu/CompanyMenu";
import HeaderCreditLimit from "./HeaderMenu/HeaderCreditLimit";
import ProfileMenu from "./HeaderMenu/ProfileMenu";

type Props = {
  additionalInfoHeader?: ReactNode;
  spaceMiddle?: boolean;
};

export default function Header(props: Props) {
  const { additionalInfoHeader, spaceMiddle } = props;
  const auth = useAuth();
  const profile = useProfile();
  const { isSuspended } = useAccountStatus();
  const [_, setHeaderHeight] = useHeaderHeight();

  const track = useTracker("landing-page");
  const content = useContentResource().CorporateHeader;
  const companyMenuRef = useRef(null);
  const profileMenuRef = useRef(null);

  function sendTracking(buttonName: string) {
    return track("corpB2b.landingPage.beforeLogin", {
      eventName: "corpB2b.LandingPage",
      eventVersion: "1.0.0",
      pageName: "HOME_PAGE",
      pageCategory: "LANDING_PAGE",
      pageReferrer: "HOME_PAGE",
      buttonName: buttonName.toUpperCase(),
      pageTitle: "Landing Page",
    });
  }

  async function handleLoginPress() {
    await sendTracking("LOGIN").send();

    auth.login();
  }

  async function handleRegisterPress(e: MouseEvent<HTMLAnchorElement>) {
    const isPrevented = e.defaultPrevented;
    const href = e.currentTarget.href;

    await sendTracking("REGISTER").send();

    if (isPrevented) {
      window.location.href = href;
    }
  }

  const useInvoice = auth.user?.paymentMethod === PaymentMethod.INVOICE;
  const isPicOrAdmin =
    (profile?.employmentDetail.isPic ||
      profile?.profile.role === "Administrator") ??
    false;

  return (
    <View
      nativeID="ctv-header"
      style={styles.wrapper}
      onLayout={(e) => setHeaderHeight(e.nativeEvent.layout.height)}
    >
      {!isSuspended && additionalInfoHeader}
      <View style={[styles.container, spaceMiddle && styles.spaceMiddle]}>
        <LocalizedLink href="/redirect/home">
          <View testID="header.home-icon">
            <Image
              alt="Traveloka for Corporates"
              src={"/images/traveloka-for-corporates.png"}
              width={282}
            />
          </View>
        </LocalizedLink>
        <View style={styles.rightMenu}>
          {auth.isAuthenticated && (
            <LocalizedLink href="/booking" style={cssStyles.button}>
              <Text testID="header.my-booking">
                {content.myBookingButtonText}
              </Text>
            </LocalizedLink>
          )}
          <LanguageMenu testID="header" />

          {!auth.isAuthenticated && (
            <>
              <Button
                testID="pre-login.header.login"
                variant="secondary"
                text={content.loginButtonText}
                style={cssStyles.button}
                onPress={handleLoginPress}
              />
              <LocalizedLink
                href="/register"
                style={cssStyles.button}
                onClick={handleRegisterPress}
              >
                <Button
                  testID="pre-login.header.register"
                  text={content.registerButtonText}
                />
              </LocalizedLink>
            </>
          )}
          {auth.isAuthenticated && (
            <>
              {auth.user.globalTripRequestApproval && (
                <Dropdown
                  alignment="left"
                  trigger={
                    <Button
                      testID="header.menu.approval.button"
                      variant="secondary"
                      text={content.approvalButtonText}
                      startIcon={<Policy color={Token.color.bluePrimary} />}
                      endIcon={<Chevron color={Token.color.darkPrimary} />}
                      style={cssStyles.button}
                    />
                  }
                >
                  <ApprovalMenu testID="header" />
                </Dropdown>
              )}
              <Dropdown
                alignment="right"
                trigger={
                  <Button
                    ref={companyMenuRef}
                    testID="header.menu.company.button"
                    variant="secondary"
                    text={auth.user.corporateAlias}
                    startIcon={<Briefcase color={Token.color.bluePrimary} />}
                    endIcon={useInvoice ? <HeaderCreditLimit /> : undefined}
                    style={cssStyles.button}
                  />
                }
              >
                <CompanyMenu
                  testID="header"
                  headerStyle={styles.companyMenuHeader}
                />
              </Dropdown>
              <Dropdown
                alignment="right"
                trigger={
                  <Button
                    ref={profileMenuRef}
                    testID="header.menu.profile.button"
                    variant="secondary"
                    text={auth.user.name}
                    startIcon={
                      <UserAccount
                        color={Token.color.bluePrimary}
                        width={16}
                        height={16}
                      />
                    }
                    endIcon={<Chevron color={Token.color.darkPrimary} />}
                    style={cssStyles.button}
                  />
                }
              >
                <ProfileMenu
                  testID="header"
                  headerStyle={styles.companyMenuHeader}
                >
                  <MenuItem
                    testID="header.menu.profile.logout"
                    LeftIcon={<SignOut color={Token.color.bluePrimary} />}
                    text={content.logoutButtonText}
                    onPress={auth.logout}
                  />
                </ProfileMenu>
              </Dropdown>
            </>
          )}
        </View>
      </View>
      {isPicOrAdmin && (
        <FirstPicCoachmark target={[companyMenuRef, profileMenuRef]} />
      )}
    </View>
  );
}

const styles = StyleSheet.create({
  wrapper: {
    boxShadow: Token.shadow.float,
    top: 0,
    zIndex: 500,
    // @ts-ignore
    position: "sticky",
    width: "100%",
    backgroundColor: Token.color.lightPrimary,
    borderTopWidth: 4,
    borderStyle: "solid",
    borderTopColor: Token.color.bluePrimary,
  },
  spaceMiddle: {
    maxWidth: 1240,
  },
  container: {
    width: "100%",
    minWidth: 1240,
    paddingVertical: Token.spacing.xs,
    paddingHorizontal: Token.spacing.l,
    marginHorizontal: "auto",
    justifyContent: "space-between",
    flexDirection: "row",
    alignItems: "center",
  },
  rightMenu: {
    flexDirection: "row",
    alignItems: "center",
  },
  companyMenuHeader: {
    backgroundColor: Token.color.lightStain,
  },
  languageButton: {
    marginLeft: Token.spacing.s,
    minWidth: 55,
  },
  innerLanguageButton: {
    justifyContent: "flex-start",
  },
});

const cssStyles = {
  button: {
    marginLeft: Token.spacing.s,
  },
};
