"use client";
import { useRef, useState } from "react";
import { StyleSheet, View } from "react-native";

import { useAuth, useContentResource } from "@ctv/core";
import {
  appendTestId,
  Button,
  Dropdown,
  DropdownHandler,
  Locale,
  Text,
  Token,
  useChangeLocale,
  useLocale,
} from "@ctv/shared-core/src";
import MenuItem from "@ctv/shared/components/MenuItem/MenuItem";

import FlagEN from "@traveloka/icon-kit-web/react/IcFlagCircularEn";
import FlagID from "@traveloka/icon-kit-web/react/IcFlagCircularId";
import FlagTH from "@traveloka/icon-kit-web/react/IcFlagCircularTh";
import FlagVN from "@traveloka/icon-kit-web/react/IcFlagCircularVn";
import CheckMark from "@traveloka/icon-kit-web/react/IcSystemCheckmark";

type Props = {
  testID?: string;
};

export default function LanguageMenu(props: Props) {
  const { testID } = props;
  const languageDropdownRef = useRef<DropdownHandler>(null!);

  const locale = useLocale();
  const { user } = useAuth();

  const [current, setCurrent] = useState<Locale>(locale);
  const changeLanguage = useChangeLocale();

  const content = useContentResource().CorporateLanguage;

  const menusEN = (locale: Locale) => ({
    text: content.englishText,
    name: locale,
    LeftIcon: <FlagEN width={24} height={24} />,
    LeftIconActive: <FlagEN width={24} height={24} />,
    onPress() {
      setCurrent(locale);
    },
  });

  const menusID = {
    text: content.indonesiaText,
    name: Locale.IDID,
    LeftIcon: <FlagID width={24} height={24} />,
    LeftIconActive: <FlagID width={24} height={24} />,
    onPress() {
      setCurrent(Locale.IDID);
    },
  };

  const menusTH = {
    text: content.thailandText,
    name: Locale.THTH,
    LeftIcon: <FlagTH width={24} height={24} />,
    LeftIconActive: <FlagTH width={24} height={24} />,
    onPress() {
      setCurrent(Locale.THTH);
    },
  };

  const menusVN = {
    text: content.vietnamText,
    name: Locale.VNVN,
    LeftIcon: <FlagVN width={24} height={24} />,
    LeftIconActive: <FlagVN width={24} height={24} />,
    onPress() {
      setCurrent(Locale.VNVN);
    },
  };

  function getMenus(country?: string) {
    switch (country) {
      case "TH":
        return [menusEN(Locale.ENTH), menusTH];
      case "VN":
        return [menusEN(Locale.ENVN), menusVN];
      case "ID":
        return [menusEN(Locale.ENID), menusID];
      default:
        // Enable if TH and VN copy resource is added
        // return [menusEN(Locale.ENID), menusID, menusTH, menusVN];
        return [menusEN(Locale.ENID), menusID];
    }
  }

  function selectLanguage() {
    changeLanguage(current);
    languageDropdownRef.current?.setIsCollapsed(true);
  }

  return (
    <Dropdown
      ref={languageDropdownRef}
      alignment="right"
      trigger={
        <Button
          testID="header.language"
          variant="text-black"
          text={languageSwitcher(locale).text}
          startIcon={LanguageIcon(locale)}
          style={Style.languageButton}
        />
      }
    >
      <View style={Style.dropdownLanguageMenu}>
        <Text style={Style.header}>{content.title}</Text>
        {getMenus(user?.country).map((menu) => (
          <MenuItem
            {...menu}
            testID={appendTestId(testID, `menu.language.${menu.name}`)}
            key={menu.name}
            style={Style.menuItem}
            textStyle={Style.menuText}
            activeStyle={Style.activeMenuItem}
            isActive={menu.name === current}
            RightIcon={""}
            RightIconActive={<CheckMark color={Token.color.bluePrimary} />}
          />
        ))}
        <View style={Style.actions}>
          <Button
            style={Style.cancelButton}
            variant="secondary"
            onPress={() => {
              languageDropdownRef.current?.setIsCollapsed(true);
            }}
            text={content.cancelButtonText}
          />
          <Button onPress={selectLanguage} text={content.selectButtonText} />
        </View>
      </View>
    </Dropdown>
  );
}

function LanguageIcon(locale: Locale) {
  switch (locale) {
    case Locale.IDID:
      return <FlagID color={Token.color.bluePrimary} width={24} height={24} />;
    case Locale.THTH:
      return <FlagTH color={Token.color.bluePrimary} width={24} height={24} />;
    case Locale.VNVN:
      return <FlagVN color={Token.color.bluePrimary} width={24} height={24} />;
    case Locale.ENID:
    case Locale.ENTH:
    case Locale.ENVN:
      return <FlagEN color={Token.color.bluePrimary} width={24} height={24} />;
  }
}

function languageSwitcher(locale: Locale) {
  switch (locale) {
    case Locale.IDID:
      return { text: "ID", icon: FlagID };
    case Locale.THTH:
      return { text: "TH", icon: FlagTH };
    case Locale.VNVN:
      return { text: "VN", icon: FlagVN };
    case Locale.ENID:
    case Locale.ENTH:
    case Locale.ENVN:
      return { text: "EN", icon: FlagEN };
  }
}

const Style = StyleSheet.create({
  languageButton: {
    marginLeft: Token.spacing.s,
    minWidth: 55,
  },
  dropdownLanguageMenu: {
    minWidth: 260,
    padding: Token.spacing.m,
  },
  header: {
    fontWeight: Token.typography.uiBaselineBold.fontWeight,
    letterSpacing: 1,
    paddingBottom: Token.spacing.m,
  },
  menuText: {
    color: Token.color.darkPrimary,
  },
  menuItem: {
    paddingVertical: Token.spacing.xs,
    paddingHorizontal: Token.spacing.xxs,
    marginHorizontal: 0,
    marginBottom: Token.spacing.m,
  },
  activeMenuItem: {
    backgroundColor: "none",
  },
  actions: {
    flexDirection: "row",
    justifyContent: "flex-end",
    marginTop: Token.spacing.xs,
  },
  cancelButton: {
    marginRight: Token.spacing.m,
  },
});
