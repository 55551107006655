import { useAuth, useContentResource } from "@ctv/core";
import { useProfile } from "@ctv/core/providers/profile/ProfileContext";
import { appendTestId, LocalizedLink, Token } from "@ctv/shared-core/src";
import { key } from "@ctv/shared/constants/data-tracking";
import { set } from "@ctv/shared/utils/expirable-storage";
import React from "react";
import { StyleProp, StyleSheet, View, ViewStyle } from "react-native";
import MenuItem from "../../MenuItem/MenuItem";

type Props = {
  currentPath?: string;
  headerStyle?: StyleProp<ViewStyle>;
  testID?: string;
};

type Menu = {
  text: string;
  url: string;
  name: string;
  onPress?(): void;
};

export default function ApprovalMenu(props: Props) {
  const { currentPath = "", testID } = props;

  const profile = useProfile();
  const { user } = useAuth();
  const content = useContentResource().CorporateApprovalMenu;

  if (!user?.globalTripRequestApproval) {
    return null;
  }

  const menus: Menu[] = [
    {
      text: content.myRequestText,
      url: "/approval/?tab=my-request",
      name: "my-request",
    },
    {
      text: content.createTripRequestText,
      url: "/approval/create",
      name: "create-trip-request",
      onPress() {
        set(
          key.CREATE_APPROVAL,
          {
            pageCategory: "APPROVAL_HEADER",
            webReferrer: window.location.href,
            eventType: "CLICK",
            clickSource: "CORPORATE_HEADER",
          },
          5 * 60 * 1000 //5 Mins expired
        );
      },
    },
  ];

  if (profile?.employmentDetail.isApprover) {
    menus.splice(1, 0, {
      text: content.needMyApprovalText,
      url: "/approval/?tab=my-approval",
      name: "need-my-approval",
    });
  }

  return (
    <View style={Style.dropdownApprovalMenu}>
      {menus.map((menu) => (
        <LocalizedLink key={menu.name} href={menu.url}>
          <MenuItem
            {...menu}
            testID={appendTestId(testID, `menu.approval.${menu.name}`)}
            key={menu.url}
            style={Style.menuItem}
            isActive={currentPath.startsWith(menu.url)}
          />
        </LocalizedLink>
      ))}
    </View>
  );
}

const Style = StyleSheet.create({
  dropdownApprovalMenu: {
    minWidth: 180,
  },
  menuItem: {
    paddingVertical: Token.spacing.s,
    marginHorizontal: 0,
  },
});
